/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import Header from "./header";
import GlobalStyle from "./global";

const BodyDiv = styled.div`
  margin: 0;
  padding: 20px;
  padding-top: 3em;
  padding-bottom: 4em;
  background-color: #e9deba;

  @media(max-width:767px) {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const Layout = ({ title, children, logo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header title={title} showLogo={logo} />

        <BodyDiv>
        <main>{children}</main>
        </BodyDiv>
        
        <GlobalStyle />  
        <footer>
          <Container>
            <Row>
              <Col sm={{ span: 4, offset: 4 }} className="center-text">
                <p className="copyright text-copy"> &copy;2020 &nbsp; Frolic Labs</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
