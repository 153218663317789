
const factData = {
  factList: [
    {title: "Name", description: "Dune Sea"},
    {title: "Developer", description: "Frolic Labs"},
    {title: "Publisher", description: "Frolic Labs"},
    {title: "Release Date (Windows)", description: "October 10, 2019"},
    {title: "Release Date (Nintendo Switch)", description: "July 1, 2020"},
    {title: "Genre", description: "Adventure, Side scroller, Flying"},  
    {title: "Platforms", description: "Nintendo Switch, Windows, Mac"},
    {title: "Engine", description: "Unity"},
  ],
  socialLinks: [
    {title: "Website", url: "https://dunesea.info"},
    {title: "Nintendo Switch", url: "https://www.nintendo.com/games/detail/dune-sea-switch"},
    {title: "Steam", url: "https://store.steampowered.com/app/1103120/Dune_Sea"},
    {title: "Itch.Io", url: "https://froliclabs.itch.io/dune-sea"},
    {title: "Discord", url: "https://discord.gg/AWHJbys"},
    {title: "Twitter", url: "http://twitter.com/duneseagame"},
    {title: "YouTube", url: " https://www.youtube.com/channel/UCbECSBjd_m0Yg_O9fkrvSuA"},
    {title: "Facebook", url: "https://www.facebook.com/froliclabs"},
    {title: "Instagram", url: "http://instagram.com/dunesea_game"},
    {title: "IndieDB", url: "https://www.indiedb.com/games/dune-sea"},
  ],

  contacts: [
    {title: "General / Business", url: "info@froliclabs.com"},
    {title: "Media Inquiries", url: "media@froliclabs.com"},
  ],

  credits: [
    {title: "Amos Laber", description: "Technical Director, Lead Programmer"},
    {title: "Bret Measor", description: "Writer, Game Designer"},
    {title: "Steve Vatkov", description: "Game Artist, Level Designer"},
    {title: "Jake Butineau", description: "Composer, Sound Designer"},
  ],
}

export default factData;
